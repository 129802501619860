'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _cookie = require('cookie');

var _cookie2 = _interopRequireDefault(_cookie);

var _objectAssign = require('object-assign');

var _objectAssign2 = _interopRequireDefault(_objectAssign);

var _utils = require('./utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Cookies = function () {
  function Cookies(cookies, hooks) {
    _classCallCheck(this, Cookies);

    this.changeListeners = [];

    this.cookies = parseCookies(cookies);
    this.hooks = hooks;
    this.HAS_DOCUMENT_COOKIE = (0, _utils.hasDocumentCookie)();
  }

  _createClass(Cookies, [{
    key: '_updateBrowserValues',
    value: function _updateBrowserValues() {
      if (!this.HAS_DOCUMENT_COOKIE) {
        return;
      }

      this.cookies = _cookie2.default.parse(document.cookie);
    }
  }, {
    key: 'get',
    value: function get(name) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._updateBrowserValues();
      return readCookie(this.cookies[name], options);
    }
  }, {
    key: 'getAll',
    value: function getAll() {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      this._updateBrowserValues();
      var result = {};

      for (var name in this.cookies) {
        result[name] = readCookie(this.cookies[name], options);
      }

      return result;
    }
  }, {
    key: 'set',
    value: function set(name, value, options) {
      if ((typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object') {
        value = JSON.stringify(value);
      }

      if (this.hooks && this.hooks.onSet) {
        this.hooks.onSet(name, value, options);
      }

      this.cookies[name] = value;

      if (this.HAS_DOCUMENT_COOKIE) {
        document.cookie = _cookie2.default.serialize(name, value, options);
      }

      this._emitChange({ name: name, value: value, options: options });
    }
  }, {
    key: 'remove',
    value: function remove(name, options) {
      var finalOptions = options = (0, _objectAssign2.default)({}, options, {
        expires: new Date(1970, 1, 1, 0, 0, 1),
        maxAge: 0
      });

      if (this.hooks && this.hooks.onRemove) {
        this.hooks.onRemove(name, finalOptions);
      }

      delete this.cookies[name];

      if (this.HAS_DOCUMENT_COOKIE) {
        document.cookie = _cookie2.default.serialize(name, '', finalOptions);
      }

      this._emitChange({ name: name, value: undefined, options: options });
    }
  }, {
    key: '_emitChange',
    value: function _emitChange(params) {
      for (var i = 0; i < this.changeListeners.length; ++i) {
        this.changeListeners[i](params);
      }
    }
  }, {
    key: 'addChangeListener',
    value: function addChangeListener(callback) {
      this.changeListeners.push(callback);
    }
  }, {
    key: 'removeChangeListener',
    value: function removeChangeListener(callback) {
      var idx = this.changeListeners.indexOf(callback);
      if (idx >= 0) {
        this.changeListeners.splice(idx, 1);
      }
    }
  }]);

  return Cookies;
}();

exports.default = Cookies;


function parseCookies(cookies) {
  if (typeof cookies === 'string') {
    return _cookie2.default.parse(cookies);
  } else if ((typeof cookies === 'undefined' ? 'undefined' : _typeof(cookies)) === 'object' && cookies !== null) {
    return cookies;
  } else {
    return {};
  }
}

function isParsingCookie(value, doNotParse) {
  if (typeof doNotParse === 'undefined') {
    // We guess if the cookie start with { or [, it has been serialized
    doNotParse = !value || value[0] !== '{' && value[0] !== '[' && value[0] !== '"';
  }

  return !doNotParse;
}

function readCookie(value) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (isParsingCookie(value, options.doNotParse)) {
    try {
      return JSON.parse(value);
    } catch (e) {
      // At least we tried
    }
  }

  return value;
}
module.exports = exports['default'];